import React from "react";

const DSEWrapper= ({bgClass, className = "", fullWidth = false, children,}) => {
  return (
    <section className={`dse-wrapper ${className} ${bgClass ? `dse-wrapper--bg-${bgClass}` : ''}`}>
      <div className={`dse-wrapper__container ${fullWidth ? 'dse-wrapper__container--full-width' : ''}`}>
        {children}
      </div>
    </section>
  )
};

export default DSEWrapper;