import React, { memo } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import footerLogo from '../../../assets/images/global/dsefooter-logo.svg'
import arrowRight from '../../../assets/images/global/dse-right-arrow-dark.svg'

const Footer = () => (
  <footer className="DSEFooter">
    <Container>
      <Row>
        <Col lg={{ span: 12, offset: 0 }}>
          <Row className='flex-lg-row-reverse'>
            <Col
              lg={{ span: 9, offset: 0, order: 1 }}
              xs={{ span: 12, offset: 0, order: 2 }}
							className='pl-lg-5'
            >
              <nav className='footer-nav-container'>
                <a
                  href='https://www.coherus.com/contact-us/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={arrowRight} alt='' />
                  <h6 className='bolder'>Contact Us</h6>
                </a>
                <a
                  href='https://www.coherus.com/privacy-policy/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={arrowRight} alt='Right arrow' />
                  <h6 className='bolder'>Privacy Policy</h6>
                </a>
              </nav>
              <div className='footer-text-container'>
                <h6 className=''>
                  © 2024 Coherus BioSciences, Inc. <span className='nowrap'>All rights reserved.</span> <span className='nowrap'>US-IO-0081 03/24</span>
                </h6>
              </div>
            </Col>
            <Col className='justify-content-center'>
              <div className='footer-logo-container'>
                <a
                  href='https://www.coherus.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={footerLogo} alt='Coherus Biosciences logo' />
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default memo(Footer)
