import React from 'react';


const DseCta = ({ className, text, action, title, type='button', href='', target='_self' }) => {

  if(type === 'link'){
    return (
      <div className='dse-cta-container'>
        <a className={ `dse-cta ${className || ''}` }
          href={ href }
          dangerouslySetInnerHTML={{__html: text}} aria-label={text}
          {...(title && { title: title })}
          target={ target }></a>
      </div>
    );
  } else {
    return (
      <button className={ `dse-cta ${className || ''}` } onClick={ action } dangerouslySetInnerHTML={{__html: text}} aria-label={text} {...(title && { title: title })}></button>
    )
  }

}

export default DseCta
