import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";

import DseCta from '../DSECta/DSECta';

import imgIcon from '../../../assets/images/global/menu-icon.svg';
import imgIconX from '../../../assets/images/global/menu-icon-x.svg';
import imgDivision from '../../../assets/images/global/menu-division.png';
import imgLogo from '../../../assets/images/global/menu-logo.png';

const MainNav = props => {
  const [isNavOpen, handleIsNavOpen] = useState(false);
  const [yDirection, handleYDirection] = useState(0);
  const links = [
    {
      text: 'What is NPC?',
      link: '/#what-is-npc',
      title: 'Understand what is Nasopharyngeal Cancer'
    },
    {
      text: 'What causes NPC?',
      link: '/#what-causes-npc',
      title: 'Discover the causes of Nasopharyngeal Cancer'
    },
    {
      text: 'What are the signs of NPC?',
      link: '/#signs-of-npc',
      title: 'Discover the signs of Nasopharyngeal Cancer'
    },
    {
      text: 'How is NPC diagnosed?',
      link: '/#how-is-npc-diagnosed',
      title: 'Learn how is the Nasopharyngeal cancer diagnosed'
    },
    {
      text: 'NPC sound check',
      link: '/#npc-sound-test',
      title: 'Sound test'
    },
    {
      text: 'How is NPC treated?',
      link: '/#how-is-npc-treated',
      title: 'How is the Nasopharyngeal Cancer treatment'
    },
    {
      text: 'Resources',
      link: '/#resources',
      title: 'Download resources to understand Nasopharyngeal Cancer'
    }
  ];

  const toggleNav = () => {
    handleIsNavOpen(!isNavOpen)

    if (isNavOpen) {
      document.body.classList.remove('scroll-none')
    } else {
      document.body.classList.add('scroll-none')
    }
  }

  const handleClick = () => {
    toggleNav();
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return function cleanUp() {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    let currentY = window.pageYOffset;
    handleYDirection(currentY)
  }

  const handleBack = () => {
    navigate('/')
  }

  return (
    <section className={`dse-main-menu ${isNavOpen ? 'open' : ''} ${yDirection > 0 ? 'dark-nav' : ''}`}>
      <div className='dse-main-menu__wrapper'>
        <div className='dse-main-menu__container'>
          <div className='dse-main-menu__links'>
            {
              links.map((el, i) => {
                return <div className='dse-main-menu__link' key={i}>
                  <AnchorLink className='dse-main-menu__link__item' to={el.link} onAnchorLinkClick={handleClick} title={el.title} stripHash><span>{el.text}</span></AnchorLink>
                </div>;
              })
            }
          </div>
          <div className='dse-main-menu__transparent' onClick={toggleNav} role='presentation'></div>
        </div>
        <div className='dse-main-menu__header'>
          <img className='dse-main-menu__icon' src={imgIcon} alt="menu icon" onClick={toggleNav} />
          <img className='dse-main-menu__icon-x' src={imgIconX} alt="menu icon" onClick={toggleNav} />
          <img className='dse-main-menu__division' src={imgDivision} alt="menu vertical line" />
          <img className='dse-main-menu__logo' src={imgLogo} alt="menu logo" />
        </div>
      </div>
      { props.showCta &&
        <div className='cta'>
          <DseCta className='small-sm' text='BACK TO&nbsp;<br class="d-lg-none"/>THE FACTS' action={handleBack}/>
        </div>
      }
    </section>
  )
}

export default MainNav
