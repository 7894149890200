import React from "react";
import { Helmet } from 'react-helmet';
import MainMenu from '../main-menu/MainMenu';
import Footer from '../DSEFooter/DSEFooter';

const DSELayout= ({
  children,
  title,
  pageTitle,
  description,
  canonicalURL,
  keyWords,
  showCta = false
}) => {
  return (
    <div className="dse-layout">
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title}</title>
        <meta name='title' content={pageTitle} />
        <meta name='description' content={description} />
        <link rel='canonical' href={canonicalURL} />
        <meta name='url' content={canonicalURL} />
        <meta name='keywords' content={keyWords} />
        <html lang='en' />
      </Helmet>
      <MainMenu showCta={showCta}></MainMenu>
      <main className='dse-main'>{children}</main>
      <Footer />
    </div>
  )
};

export default DSELayout;